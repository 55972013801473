<!-- eslint-disabled -->
<template>
  <div class="Questions min-h-screen">
    <div id="details" class="flex">
      <div class="w-1/5 justify-center flex items-center">
        <a href="/">
          <Logo class="logo" />
        </a>
      </div>
      <div class="w-3/5 flex items-center justify-center progression">
        <div class="block w-full mb-1">
          <span class="font-bold">
            Benötigte Zeit: {{ countUp }}s
          </span>
          <span class="float-right font-bold">
            {{ index }}/{{ questions.length }}
          </span>
        </div>
        <div id="progressionBar">
          <div id="progression" />
        </div>
      </div>
      <div class="w-1/5 justify-center flex items-center">
        <a href="/">
          <Close class="close" />
        </a>
      </div>
    </div>
    <div id="content" class="flex py-20 overflow-y-auto">
      <div class="w-1/5 flex justify-center px-4">
        <span id="qNumber" class="h1">{{ index }}.</span>
      </div>
      <div class="w-3/5">
        <h1 class="mb-12">
          {{ question }}
        </h1>
        <p id="answerText" class="block mb-1 font-bold">
          Deine Antwort:
        </p>
        <div class="answers mb-3">
          <div
            v-for="(answer,index) in answers"
            :key="index"
            :class="
              ['answer','answer-'+(index)]"
            :data-id="index"
            @click="selectAnswer($event)"
          >
            <p class="list-item font-bold">
              {{ answer }}
            </p>
            <close class="wrong-icon" />
            <correct class="correct-icon" />
          </div>
        </div>
        <div
          v-if="index < questions.length && selected"
          class="float-right button w-auto"
          @click="next"
        >
          Nächste Frage
        </div>
        <div
          v-else-if="index == questions.length && selected"
          class="float-right button w-auto"
          @click="next"
        >
          Abschliessen
        </div>
      </div>
      <div class="w-1/5" />
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/images/UPD-Logo-Colour.svg';
import Close from '@/assets/images/icons/close.svg';
import Correct from '@/components/utils/Correct.vue';

export default {
  components: {
    Logo, Close, Correct,
  },
  props: { index: Number },
  data: () => ({
    questions: [],
    answers: [],
    question: '',
    countUp: 0,
    selected: false,
    multipleSelected: [],
  }),
  created() {
    this.countUpTimer();
  },
  mounted() {
    this.questions = this.$tm('questions');
    this.question = this.$tm(`questions[${this.index - 1}].question`);
    this.answers = this.$tm(`questions[${this.index - 1}].answers`);
    document.getElementById('progression').style.width = `${((this.index) / this.questions.length) * 100}%`;
  },
  methods: {
    countUpTimer() {
      setTimeout(() => {
        this.countUp += 1;
        this.countUpTimer();
      }, 1000);
    },
    selectAnswer(e) {
      const elems = document.getElementsByClassName('answer');
      const type = this.$tm(`questions[${this.index - 1}].type`);
      const id = e.currentTarget.getAttribute('data-id');
      // eslint-disable-next-line
      if (type === 'radio') {
        [].forEach.call(elems, (el) => {
          el.classList.remove('selected');
        });
        this.$store.state.answers[this.index] = id;
        this.selected = true;
      } else {
        if (e.currentTarget.classList.contains('selected')) {
          const index = this.multipleSelected.indexOf(id);
          if (index > -1) {
            this.multipleSelected.splice(index, 1);
          }
        } else {
          this.multipleSelected.push(id);
        }
        this.multipleSelected.sort();
        this.$store.state.answers[this.index] = this.multipleSelected;

        if (this.multipleSelected.length > 0) {
          this.selected = true;
        } else {
          this.selected = false;
        }
      }
      e.currentTarget.classList.toggle('selected');
      // console.log(this.$store.state.answers);
    },
    showCorrectAnswer() {
      const answer = this.$tm(`questions[${this.index - 1}].correct`);
      // eslint-disable-next-line
      if (Array.isArray(answer)) {
        // eslint-disable-next-line
        const same = (this.multipleSelected.length == answer.length) && this.multipleSelected.every(function(element, index) {
          return element === answer[index];
        });
        if (same) {
          this.$store.state.correct[this.index] = true;
          // eslint-disable-next-line
          document.querySelectorAll('.selected').forEach((el) => {
            el.classList.add('correct');
          });
        } else {
          // eslint-disable-next-line
          document.querySelectorAll('.answer').forEach((el) => {
            const id = el.getAttribute('data-id');
            if (answer.includes(id)) {
              // eslint-disable-next-line
              el.classList.add('correct');
            } else {
              el.classList.add('wrong');
            }
          });
        }
      } else {
        const selected = document.querySelector('.selected').getAttribute('data-id');
        // eslint-disable-next-line
        if (selected === answer) {
          this.$store.state.correct[this.index] = true;
          // eslint-disable-next-line
          document.querySelectorAll('.selected[data-id="' + answer + '"]').forEach((el) => {
            el.classList.add('correct');
          });
        } else {
          // eslint-disable-next-line
          document.querySelectorAll('.answer[data-id="' + answer + '"]').forEach((el) => {
            el.classList.add('correct');
          });
          // eslint-disable-next-line
          document.querySelectorAll('.answer[data-id="' + selected + '"]').forEach((el) => {
            el.classList.add('wrong');
          });
        }
      }
    },
    next() {
      document.getElementById('answerText').innerHTML = 'Lösung:';
      this.showCorrectAnswer();
      // eslint-disable-next-line
      setTimeout(() => {
        document.getElementById('content').style.opacity = '0';
        const newIndex = Number(this.index) + 1;
        setTimeout(() => {
          // eslint-disable-next-line
          if (this.index == this.questions.length) {
            // console.log(1);
            this.$store.state.seconds = this.countUp;
            // eslint-disable-next-line
            this.$router.push({ path: `/finish` });
          } else {
            // console.log(2);
            document.getElementById('progression').style.width = `${((newIndex) / this.questions.length) * 100}%`;
            this.selected = false;
            this.$router.push({ path: `/question/${newIndex}` });
            this.question = this.$tm(`questions[${this.index}].question`);
            this.answers = this.$tm(`questions[${this.index}].answers`);
            this.multipleSelected = [];
            const elems = document.getElementsByClassName('answer');
            [].forEach.call(elems, (el) => {
              el.classList.remove('selected');
              el.classList.remove('correct');
              el.classList.remove('wrong');
            });
            document.getElementById('answerText').innerHTML = 'Deine Antwort:';
            document.getElementById('content').style.opacity = '1';
          }
        }, 500);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.Questions {
  color: var(--gray);

  :deep(svg path) {
    stroke: var(--gray-with-alpha);
  }
}
#details {
  background-color: var(--brightgray);
  height: 13%;
  padding: 1.2rem 0;
}
#progressionBar {
  height: 10px;
  width: 100%;
  background-color: var(--gray-with-alpha);
  border-radius: 999px;
}
#progression {
  height: 100%;
  width: 0%;
  background-color: var(--red);
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
}
#qNumber {
  font-size: 3.5rem;
  line-height: 0.9;
  color: var(--red);
}
.progression {
  flex-direction: column;
}
.logo {
  max-height: 70px;
  width: 100%;
}
.close {
  transform: scale(0.7);
}
.header {
  background-image: url("../../assets/images/header.png?uri");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0px -22rem;
}
#content {
  transition: all 0.5s ease-in-out;
  height: 87%;
}
</style>
