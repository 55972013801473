<template>
  <div class="icon" />
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  border-left: 4px solid white;
  border-bottom: 4px solid white;
  height: 1.25rem;
  width: 1.875rem;
  transform: rotate(-45deg) scale(0.6);
}
</style>
